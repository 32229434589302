
const isDataLayerAvailable = () => typeof window !== 'undefined' && Array.isArray(window.dataLayer);

const trackEvent = ({ action, category, label, value, conversionLabel, page }) => {
  const pageLocation = page || (typeof window !== 'undefined' ? window.location.href : '');

  if (isDataLayerAvailable()) {
    window.dataLayer.push({
      event: action,
      event_category: category,
      event_label: label,
      value,
      conversion_label: conversionLabel,
      page_location: pageLocation,
    });
  } else {
    console.warn('Google Tag Manager no está disponible.');
  }
};

// visitas largas a la página (más de 1 minuto)
export const trackLongPageVisit = () => {
  if (isDataLayerAvailable()) {
    setTimeout(() => {
      trackEvent({
        action: 'long_page_visit',
        category: 'Page Visit',
        label: 'Vista de página + de 1 min',
        value: 1,
        conversionLabel: 'VLG6CKrBnfUZEKna9O8D',
      });
    }, 60000); // Espera de 60 segundos
  } else {
    console.warn('Google Tag Manager no está disponible.');
  }
};

// descargas de brochures
export const trackBrochureDownload = (projectName) => {
  trackEvent({
    action: 'brochure_download',
    category: 'Brochure',
    label: `Brochure descargado de ${projectName}`,
    value: 1,
    conversionLabel: 'gQzSCK3BnfUZEKna9O8D',
  });
};

//  filtros búsquedas 
export const trackSearch = (searchQuery) => {
  trackEvent({
    action: 'search',
    category: 'Search',
    label: `Búsquedas realizadas: ${JSON.stringify(searchQuery)}`,
    value: 1,
    conversionLabel: '8iqwCLDBnfUZEKna9O8D',
  });
};

// envíos de formularios
export const trackFormSubmission = (formName) => {
  trackEvent({
    action: 'form_submission',
    category: 'Contact Form',
    label: `Consulta desde emprendimiento: ${formName}`,
    value: 1,
    conversionLabel: 'P7CtCPe5g4gBEKna9O8D',
  });
};

